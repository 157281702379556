<template>
    <div class="payment-container ">
        <iframe style="width: 100%;z-index: 1090;border: unset;" _kpayment class="kpayment-m-top-hide "
            :src="'/Payment-Gateway/embeddedKBank.html?order_id=' + receiptObjectId + '&amount=' + docTotal + '&name=' + shopName + '&paymentUrl=' + silomPaymentUrl"></iframe>
    </div>
</template>
<script>

export default {
    props: ['docTotal'],
    data() {
        return {
            receiptObjectId: '',
            shopName: '',
            silomPaymentUrl: process.env.VUE_APP_PAYMENT_GATEWAY_URL,
        };
    },

    created() {
        this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
        this.shopName = localStorage.getItem('shopName') || ''
    },

    methods: {
    }
}
</script>
<style>
.payment-container {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1090;
}

.modal-body {
    padding: 0rem;
}
</style>
