<template>
    <div>
        <CRow>
            <CCol class="text-left mr-2 text-grey d-flex justify-content-end "> <strong
                    @click="$router.push('/dine-in/mycart')" class="" style="color: #6b6b6b; font-size: 30px">
                    <img src="/img/close.png" width="40px" style="opacity: 0.8;">
                </strong></CCol>
        </CRow>
        <div v-if="currentStage == 0" class="">
            <div style="margin-bottom: 200px;" class="d-flex justify-content-center">
                <div class="receipt-summary text-center">
                    <div class="p-4">
                        <h5 class="mt-4">{{ $t('docTotal') }}</h5>
                        <h2>{{ SKUPrice(total) }}฿</h2>
                        <div class="row mt-5" style="font-size: medium;">
                            <div class="col-6 text-left mt-2">{{ $t('orderItem') }}</div>
                            <div class="col-6 text-right mt-2">{{ itemAmount }} {{ $t('item') }} </div>
                            <div class="col-6 text-left mt-2">{{ $t('netAmount') }}</div>
                            <div class="col-6 text-right mt-2">{{ SKUPrice(netAmount) }}</div>

                            <template v-if="!isVatInclude">
                                <div class="col-6 text-left mt-2">{{ $t('netAmountVATTotal') }}</div>
                                <div style="white-space: nowrap;" class="col-6 text-right mt-2">{{
                        SKUPrice(netAmountVATTotal) }}
                                    ฿</div>
                                <div class="col-8 text-left mt-2">{{ $t('vatAmount') }} {{ vatBase }}%</div>
                                <div style="white-space: nowrap;" class="col-4 text-right mt-2">{{ SKUPrice(vatAmount)
                                    }} ฿
                                </div>

                            </template>
                            <template v-if="serviceCharge > 0">
                                <div class="col-8 text-left mt-2">{{ $t('serviceCharge') }}</div>
                                <div style="white-space: nowrap;" class="col-4 text-right mt-2">{{ serviceCharge }} ฿
                                </div>
                            </template>
                            <template v-if="discount > 0">
                                <div class="col-7 text-left mt-2">{{ $t('discount') }}</div>
                                <div style="white-space: nowrap;" class="col-5 text-right mt-2">- {{ SKUPrice(discount)
                                    }} ฿</div>
                            </template>
                            <div class="col-6 text-left mt-4">{{ $t('table') }}</div>
                            <div class="col-6 text-right mt-4">{{ tableName }}</div>
                            <div class="col-6 text-left mt-2">{{ $t('numberOfGuest') }}</div>
                            <div class="col-6 text-right mt-2">{{ receiptDoc.numCust }} {{ $t('guest') }}</div>

                            <div class="col-6 text-left mt-2">{{ $t('orderTime') }}</div>
                            <div class="col-6 text-right mt-2">{{ getQRTimeStamp()
                                }}</div>
                        </div>
                    </div>
                    <br>
                    <span>
                        Powered by
                        <img src="/logo.png" width="30%" alt="" class="img-fluid" />
                    </span>
                </div>
            </div>
            <footer class="fixed-bottom" style="padding: 20px; background-color: #ffffff">
                <div class="row">
                    <div class="col">
                        <h4>{{ $t('docTotal') }}</h4>
                    </div>
                    <div class="col">
                        <h4 class="font-weight-bold text-right">฿{{ SKUPrice(total) }}</h4>
                    </div>
                </div>
                <hr />
                <CButton class="btn-selecteditem" style="font-size: x-large; color: white;" size="lg" block
                    @click="nextStage(1)">
                    <span> {{ $t('selectPaymentMethod') }} </span>
                </CButton>
            </footer>
        </div>

        <div v-if="currentStage == 1">
            <div v-if="isVisible" class="fade-in-element" style="margin-bottom: 200px;">
                <CRow class="mb-3">
                    <CCol class="text-center mt-1">
                        <h4 style="font-weight: bold;">{{ $t('selectPaymentMethod') }}</h4>
                    </CCol>
                </CRow>
                <CRow class="text-center">
                    <CCol>
                        <CButton v-if="enabledKPaymentGateway == true"
                            :class="{ 'mt-4': true, 'btn-square-payment': true, 'p-4': true, 'check-control': activeButton === 0 }"
                            @click="setActiveButton(0)">
                            <div class="row pt-2">
                                <div class="col-5"><i class="fi fi-rr-barcode-read" style="font-size: 40px;"></i>
                                </div>
                                <div class="col text-left pt-2">
                                    <h4>{{ $t('QRPayment') }}</h4>
                                </div>
                            </div>
                        </CButton>


                        <CButton v-if="enabledKPaymentGateway == true"
                            :class="{ 'mt-4': true, 'btn-square-payment': true, 'p-4': true, 'check-control': activeButton === 1 }"
                            @click="setActiveButton(1)">
                            <div class="row pt-2">
                                <div class="col-5"><i class="fi fi-rr-credit-card" style="font-size: 40px;"></i>
                                </div>
                                <div class="col text-left pt-2">
                                    <h4>{{ $t('cardPayment') }}</h4>
                                </div>
                            </div>
                        </CButton>

                        <CButton
                            :class="{ 'mt-4': true, 'btn-square-payment': true, 'p-4': true, 'check-control': activeButton === 2 }"
                            @click="setActiveButton(2)">
                            <div class="row pt-2">
                                <div class="col-5"><i class="fi fi-rr-usd-circle" style="font-size: 40px;"></i>
                                </div>
                                <div class="col text-left pt-2">
                                    <h4>{{ $t('payWithStaff') }}</h4>
                                </div>
                            </div>
                        </CButton>

                    </CCol>
                </CRow>
            </div>

            <footer class="fixed-bottom" style="padding: 20px; background-color: #ffffff">
                <div class="row">
                    <div class="col">
                        <h4>{{ $t('docTotal') }}</h4>
                    </div>
                    <div class="col">
                        <h4 class="font-weight-bold text-right">฿{{ SKUPrice(total) }}</h4>
                    </div>
                </div>
                <hr />
                <CButton class="btn-selecteditem" style="font-size: x-large; color: white;" size="lg" block
                    @click="checkOut()">
                    <span> {{ switchButton() }} </span>
                </CButton>
            </footer>

        </div>
        <!-- QR Payment -->
        <CModal v-if="enabledKPaymentGateway == true" style="padding: 0rem;" :show.sync="showQrModal" size="sm">
            <div class="header-view">
                <div style="font-size:20px;margin-top: 14px;">
                    {{ $t('paymentConfirmation') }}</div>
            </div>
            <div style="position: fixed; top: 80px;  width: 100%;z-index: 1060;">
                <div class="row">
                    <div class="col-12 text-center d-flex justify-content-center">
                        <div class="receipt-summary text-center">
                            <div class="p-4">
                                <h5 class="mt-4">{{ $t('paymentAmount') }}</h5>
                                <h1>{{ SKUPrice(total) }}฿</h1>
                            </div>
                            <br>
                            <h5>{{ $t('PaymentMethod') }} : {{
                        $t('QRCode') }}</h5>
                        </div>
                    </div>
                    <div v-if="$i18n.locale == 'th'" class="col-12 text-center d-flex justify-content-center">
                        <div style="width: 80%;font-size: large;" class="mt-5 text-center">
                            โปรดตรวจสอบรายละเอียดการชำระเงิน<br>และคลิก 'ชำระเงิน' เพื่อดำเนินการ<br>ต่อไปยังหน้า {{
                        $t('QRCode') }}
                            <br>
                            <div class="mt-5 text-description">การชำระเงินของคุณได้รับการ<br>ประมวลผลอย่างปลอดภัยโดย
                                SilomPOS
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 text-center d-flex justify-content-center">
                        <div style="width: 80%;font-size: large;" class="mt-5 text-center">
                            Please review the details and <br> click 'Pay now
                            <br>
                            <div class="mt-5 text-description">Your payment is securely processed by <br> Silom POS
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <QRPayment class="" v-if="loaded && QRPaymentSelected == true" v-bind:docTotal="total">
            </QRPayment>
            <template #footer-wrapper>
                <div></div>
            </template>
        </CModal>
        <!-- Credit Card Payment -->
        <CModal v-if="enabledKPaymentGateway == true" :show.sync="showCreditCardModal" size="sm">
            <div class="header-view">
                <div style="font-size:20px;margin-top: 14px;">
                    {{ $t('paymentConfirmation') }}</div>
            </div>
            <div style="position: fixed; top: 80px;  width: 100%;z-index: 1060;">
                <div class="row">
                    <div class="col-12 text-center d-flex justify-content-center">
                        <div class="receipt-summary text-center">
                            <div class="p-4">
                                <h5 class="mt-4">{{ $t('paymentAmount') }}</h5>
                                <h1>{{ SKUPrice(total) }}฿</h1>
                            </div>
                            <br>
                            <h5>{{ $t('PaymentMethod') }} : {{ $t('paymentType1') }}</h5>
                        </div>
                    </div>
                    <div v-if="$i18n.locale == 'th'" class="col-12 text-center d-flex justify-content-center">
                        <div style="width: 80%;font-size: large;" class="mt-5 text-center">
                            โปรดตรวจสอบรายละเอียดการชำระเงิน<br>และคลิก 'ชำระเงิน'
                            เพื่อดำเนินการ<br>ต่อไปยังหน้ากรอกบัตรเครดิต
                            <br>
                            <div class="mt-5 text-description">การชำระเงินของคุณได้รับการ<br>ประมวลผลอย่างปลอดภัยโดย
                                SilomPOS
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 text-center d-flex justify-content-center">
                        <div style="width: 80%;font-size: large;" class="mt-5 text-center">
                            Please review the details and <br> click 'Pay now
                            <br>
                            <div class="mt-5 text-description">Your payment is securely processed by <br> Silom POS
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <CreditCardPayment v-if="loaded" v-bind:docTotal="total">
            </CreditCardPayment>
            <template #footer-wrapper>
                <div></div>
            </template>
        </CModal>

        <CModal :show.sync="showCallStaff" size="sm">
            <div class="header-view">
                <div style="font-size:20px;margin-top: 14px;">
                    {{ $t('summaryPayment') }}</div>
            </div>
            <div
                style="position: fixed; top: 0; width: 100%; display: flex; justify-content: center;z-index: 1060;margin-top: 80px;">
                <div class="receipt-summary text-center">
                    <div class="p-4">
                        <h5 class="mt-4">{{ $t('docTotal') }}</h5>
                        <h2>{{ SKUPrice(total) }}฿</h2>
                        <div class="row mt-5" style="font-size: medium;">
                            <div class="col-6 text-left mt-2">{{ $t('orderTime') }}</div>
                            <div class="col-6 text-right mt-2">{{
                        getQRTimeStamp()
                    }}</div>
                            <div class="col-6 text-left mt-4">{{ $t('table') }}</div>
                            <div class="col-6 text-right mt-4">{{ tableName }}</div>
                            <div class="col-6 text-left mt-2">{{ $t('numberOfGuest') }}</div>
                            <div class="col-6 text-right mt-2">{{ receiptDoc.numCust }} {{ $t('guest') }}</div>
                            <div class="col-6 text-left mt-2">{{ $t('orderItem') }}</div>
                            <div class="col-6 text-right mt-2">{{ itemAmount }} {{ $t('item') }} </div>
                            <div class="col-6 text-left mt-2">{{ $t('netAmount') }}</div>
                            <div class="col-6 text-right mt-2">{{ SKUPrice(netAmount) }}</div>

                            <template v-if="!isVatInclude">
                                <div class="col-6 text-left mt-2">{{ $t('netAmountVATTotal') }}</div>
                                <div class="col-6 text-right mt-2">{{ SKUPrice(netAmountVATTotal) }} ฿</div>
                                <div class="col-8 text-left mt-2">{{ $t('vatAmount') }} {{ vatBase }}%</div>
                                <div class="col-4 text-right mt-2">{{ SKUPrice(vatAmount) }} ฿</div>
                            </template>
                            <template v-if="serviceCharge > 0">
                                <div class="col-8 text-left mt-2">{{ $t('serviceCharge') }}</div>
                                <div class="col-4 text-right mt-2">{{ SKUPrice(serviceCharge) }} ฿</div>
                            </template>
                            <template v-if="discount > 0">
                                <div class="col-7 text-left mt-2">{{ $t('discount') }}</div>
                                <div class="col-5 text-right mt-2">- {{ SKUPrice(discount) }} ฿</div>
                            </template>

                        </div>
                    </div>
                    <br>
                    <span>
                        Powered by
                        <img src="/logo.png" width="30%" alt="" class="img-fluid" />
                    </span>
                </div>

            </div>

            <template #footer-wrapper>
                <div class="text-center pb-4">
                    <h4>{{ $t('messageSent') }}...</h4>
                </div>
            </template>
        </CModal>
    </div>

</template>

<script>
import '@/util/menu.css'
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import QRPayment from './QRPayment.vue'
import CreditCardPayment from './CreditCardPayment.vue'
import moment, { ISO_8601 } from 'moment'
import { mapGetters } from 'vuex'
import payment from '@/services/payment'
import signed from '@/util/signed'
import asymmetric from '@/util/asymmetric'

export default {
    components: {
        QRPayment,
        CreditCardPayment,
    },
    data() {
        return {
            isVisible: false,
            activeButton: 0,
            receiptItems: [],
            showQrModal: false,
            showCreditCardModal: false,
            loaded: false,
            total: 0,
            receiptDoc: [],
            tableName: '',
            itemAmount: 0,
            receiptObjectId: '',
            paymentStatus: '',
            intervalId: null,
            table: {},
            showCallStaff: false,
            netAmountVATTotal: 0,
            isVatInclude: true,
            vatBase: '',
            vatAmount: 0,
            serviceCharge: 0,
            QRPaymentSelected: false,
            receiptId: '',
            enabledKPaymentGateway: Boolean,
            currentStage: 0,
            discount: 0,
            netAmount: 0,
            QRTimestamp: '',
        };
    },
    mounted() {
        this.isVisible = true
        this.intervalId = setInterval(this.getLocalStorageValue, 3000)
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        grandTotal() {
            let total = this.receiptItems.reduce((accum, product) => {
                return accum + (product.deleted_at === undefined ? product.totalAmount : 0)
            }, 0)

            return util.convertCurrency(total)
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        shop() {
            return this.shopLineOA.find(
                (detail) => detail.objectId === this.shopObjectId
            )
        },
    },
    created() {
        this.activeButton = 0
        this.enabledKPaymentGateway = this.shop.enabledKPaymentGateway || false
        this.kPaymentGatewayPKey = this.getPKey()
        this.table = JSON.parse(localStorage.getItem('table')) || []
        this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
        this.receiptId = localStorage.getItem('receiptId') || this.receiptObjectId
        this.getReceiptTransaction()
        localStorage.setItem('creditCardPayStatus', '');
        if (this.enabledKPaymentGateway === false) {
            this.activeButton = 2;
        }

    },
    methods: {
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'make_payment', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        getQRTimeStamp() {
            return util.convertTime(this.QRTimestamp)
        },
        nextStage(i) {
            this.currentStage = i
            this.getReceiptTransaction()
        },
        getPKey() {
            let token = signed.sign()
            // const token = asymmetric.asymmetric()
            payment({
                method: 'get',
                url: "/embedded/v1.1/kbank/config/" + this.uid + "?shopObjectId=" + this.shopObjectId,
                headers: {
                    authorization: token,
                },
            }).then((res) => {
                localStorage.setItem("pkey", res.data.data.pkey)
                localStorage.setItem("mid", res.data.data.mid)
                return res.data.data.pkey
            })
        },
        getLocalStorageValue() {
            let creditCardPayStatus = localStorage.getItem('creditCardPayStatus');
            if (creditCardPayStatus == 'Pending') {
                clearInterval(this.intervalId);
                this.intervalId = null;
                if (window.location.pathname == '/dine-in/paymentgateway') {
                    localStorage.setItem('creditCardPayStatus', 'Card')
                    this.$router.push('/dine-in/pending/payment')
                }
            }
        },
        SKUPrice(price) {
            return util.convertCurrency(Number(price))
        },
        checkOut() {
            this.getReceiptTransaction()
            if (this.enabledKPaymentGateway == true) {
                switch (this.activeButton) {
                    case 0:
                        this.trackButtonClick('Pay by QR')
                        localStorage.setItem('paymentStatus', true);
                        localStorage.setItem('creditCardPayStatus', 'QR');
                        this.QRPaymentSelected = true
                        this.showQrModal = true
                        this.updatePaymentStatus()
                        break;
                    case 1:
                        this.trackButtonClick('Pay by Credit Card')
                        localStorage.setItem('paymentStatus', true);
                        localStorage.setItem('creditCardPayStatus', 'Card');
                        this.paymentStatus = 'Selected';
                        this.showCreditCardModal = true;
                        this.updatePaymentStatus()
                        break;
                    case 2:
                        this.trackButtonClick('Pay with Staff')
                        this.callStaff();
                        break;
                    default:
                        break;
                }
            } else {
                this.callStaff();
            }

        },
        async callStaff() {
            this.showCallStaff = true
            let data = {
                "id": "",
                "uid": this.uid,
                "receiptObjectId": this.receiptObjectId,
                "shop": {
                    "id": this.shop.id,
                    "objectId": this.shopObjectId,
                    "shopName": this.shop.shopName,
                    "branchName": this.shop.branchName
                },
                "scope": "table",
                "scopeData": {
                    "id": this.table.id,
                    "objectId": this.table.objectId
                },
                "title": "New Message",
                "message": 'ชำระเงิน',
                "created_at": moment().toISOString(),
                "status": "new",
                "action": "alert",
                "priority": "low",
                "read_at": null,
                "expired": null,
                "group": "none",
                "ref": "none"
            }
            try {
                await poscrm({
                    url: process.env.VUE_APP_MESSAGE_CALLSTAFF,
                    data: data,
                    method: 'post',
                }).then((res) => {

                })
            } catch (error) {
                throw new Error(error)
            }

        },
        switchButton() {
            if (this.enabledKPaymentGateway == true) {
                let text = ['checkOut', 'checkOut', 'callStaff']
                return this.$i18n.t(text[this.activeButton])
            } else {
                return this.$i18n.t('callStaff')
            }

        },
        setActiveButton(buttonNumber) {
            this.activeButton = buttonNumber;
        },
        async updatePaymentStatus() {
            let timestamp = localStorage.getItem('timestamp')
            if (this.receiptId !== undefined) {
                let data = {
                    objectId: this.receiptObjectId,
                    uid: this.uid,
                    shopObjectId: this.shopObjectId,
                    receipt: {
                        source: 'SELF_ORDER',
                        QRTimestamp: timestamp,
                        receiptStatus: 'O',
                        table: {
                            tableStatus: 'P',
                        },
                        receiptItems: this.receiptItems,
                        objectId: this.receiptObjectId,
                        id: this.receiptId,
                        isVATIncluded: this.isVATIncluded,
                    },
                }

                try {
                    await poscrm({
                        url: '/api/v1.1/' + this.uid + '/Receipt/updateorder',
                        data: data,
                        method: 'post',
                    }).then((res) => {
                        console.log('Locked Payment')
                    })
                } catch (error) {
                    throw new Error(error)
                }

            }
        },

        async getReceiptTransaction() {
            try {
                await poscrm
                    .get('/api/v1.1/' + this.uid + '/Receipt/data/' + this.receiptObjectId)
                    .then((res) => {
                        if (res.data.data.document != null) {
                            let document = res.data.data.document || {}
                            const table = document.table || {}
                            this.receiptDoc = document
                            this.QRTimestamp = document.QRTimestamp || document.updated_at
                            this.tableName = table.name || document.tableName
                            this.total = document.grandTotal
                            this.itemAmount = document.receiptItems.length
                            this.loaded = true
                            this.receiptItems = document.receiptItems
                            this.receiptObjectId = document.objectId
                            this.netAmountVATTotal = document.netAmountVATTotal.toFixed(2)
                            this.isVatInclude = document.isVATIncluded
                            this.vatBase = document.vatBase
                            this.vatAmount = document.vatAmount.toFixed(2)
                            this.serviceCharge = document.serviceCharge.toFixed(2)
                            this.discount = document.discountAmount.toFixed(2)
                            this.netAmount = document.netAmount.toFixed(2)

                            if (document.table?.tableStatus == 'P') {
                                this.$router.push('/dine-in/pending/payment')
                            }
                            else if (document?.receiptStatus == 'A') {
                                this.$router.push('/dine-in/menulists')
                            }
                            else if (document.deleted_at != null) {
                                localStorage.clear()
                                sessionStorage.clear()
                                this.$router.push('/texp?errorCode=-1005')
                            }
                        }
                    })
            } catch (error) {
                throw new Error(error)
            }

        },
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.check-control {
    background-color: var(--bg-color-theme);
    color: rgb(255, 255, 255);
}

.btn:hover {
    color: #ffffff;
    text-decoration: none;
}

.receipt-summary {
    width: 80%;
    padding-bottom: 70px;
    background-color: #eeeeee;
    border-radius: 5px;
    -webkit-mask-image: radial-gradient(circle at 50% 8px, transparent 8px, red 8.5px);
    -webkit-mask-position: 50% -8px;
    -webkit-mask-size: 16px 100%;
}

.modal-body {
    padding: 0rem !important;
}

.header-view {
    background-color: #219b78;
    color: #ffffff;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1060 !important;
}
</style>
