const jwt = require('jsonwebtoken');
const moment = require('moment-timezone');

exports.sign = (claim = {}) => { 
    claim['exp'] = moment().add(1,'hour').unix()
    let publicKey = process.env.VUE_APP_PUBLIC_KEY
  
    let lockkey = Buffer.from(publicKey).toString('base64')
    try {
        let token = jwt.sign(claim, lockkey, {
            algorithm: process.env.VUE_APP_JWT_ALGORITHM,
        
        })
        return token
    } catch (e) {
        console.error(e)
        return null
    }
}
